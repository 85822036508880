// import packageJson from '../../../package.json';
export const REACT_APP_API_HOSTNAME = process.env.REACT_APP_API_HOSTNAME;
export const API_URL_PATH = `${REACT_APP_API_HOSTNAME}/contact/v1`;
export const SMSAPI_URL_PATH = `${REACT_APP_API_HOSTNAME}/sms/v1`;
export const SMSAPI_GRPC_URL_PATH = `${REACT_APP_API_HOSTNAME}/sms_grpc/v1/sms/v1`;
export const UI_API_URL_PATH = `${REACT_APP_API_HOSTNAME}/reader/v1` ;

// export const API_URL_PATH = 'https://api.edumarcsms.com/contact/v1';
export const SMART_URL = process.env.REACT_APP_SMART_URL
export const GA_ID = process.env.REACT_APP_GA_ID
export const GA_DEBUG = process.env.REACT_APP_DEBUG

export const LOGIN = 'LOGIN';
// export const LOGIN_URL = `${ API_URL_PATH }/api/auth/sign-in`;
export const LOGIN_URL = `${ UI_API_URL_PATH }/api/v1/signin`;

export const LOGOUT = 'LOGOUT';
// export const LOGOUT_URL = `${ API_URL_PATH }/api/auth/sign-out`;
export const LOGOUT_URL = `${ UI_API_URL_PATH }/api/v1/signout`;

export const GET_ALL_USER = 'GET_ALL_USER';
export const USER_URL = `${ API_URL_PATH }/api/contact`;
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DOWNLOAD_CONTACT = 'DOWNLOAD_CONTACT';
export const DOWNLOAD_CONTACT_URL = `${ API_URL_PATH }/api/contact/sheet`;
export const GET_CONTACT_GROUP = 'GET_CONTACT_GROUP';
export const GET_CONTACT_GROUP_URL = `${ API_URL_PATH }/api/contact/contactGroup`;

export const UPDATE_SMS_DETAILS = 'UPDATE_SMS_DETAILS';
export const SEND_SMS = 'SEND_SMS';
export const SEND_SMS_URL = `${ SMSAPI_GRPC_URL_PATH }/api/v1/ui/org/sendsms`;
export const SEND_CUSTOM_SMS = 'SEND_CUSTOM_SMS';

export const GET_SMS_HISTORY = 'GET_SMS_HISTORY';
// export const GET_SMS_HISTORY_URL = `${ SMSAPI_URL_PATH }/api/smsHistory`;

export const GET_SMS_HISTORY_URL = `${ UI_API_URL_PATH }/api/v1/org/me/campaign`;

export const GET_ALL_SMS_HISTORY = 'GET_ALL_SMS_HISTORY';
// export const GET_ALL_SMS_HISTORY_URL = `${ SMSAPI_URL_PATH }/api/smstransaction`;
export const GET_ALL_SMS_HISTORY_URL = `${ UI_API_URL_PATH }/api/v1/org/me/message`;
export const GET_TRACK_CAMPAIGN = 'GET_TRACK_CAMPAIGN';
export const GET_TRACK_CAMPAIGN_DELIVERY = 'GET_TRACK_CAMPAIGN';
export const GET_TRACK_CAMPAIGN_DELIVERY_URL = `${ UI_API_URL_PATH }/api/v1/org/me/campaign/{campaign_id}/tracking`;

export const GET_STUDENT = 'GET_STUDENT';
export const DELETE_STUDENT = 'DELETE_STUDENT';
export const ADD_STUDENT = 'ADD_STUDENT';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const STUDENT_URL = `${ API_URL_PATH }/api/student`;

export const GET_TEACHER = 'GET_TEACHER';
export const DELETE_TEACHER = 'DELETE_TEACHER';
export const ADD_TEACHER = 'ADD_TEACHER';
export const UPDATE_TEACHER = 'UPDATE_TEACHER';
export const TEACHER_URL = `${ API_URL_PATH }/api/teacher`;

export const GROUP_URL = `${ API_URL_PATH }/api/group`;
export const GROUP_CONTACT_URL = `${ API_URL_PATH }/api/group/gId/contact`;
export const GROUP_DETAILS = 'GROUP_DETAILS';
export const ADD_USER_GROUP = 'ADD_USER_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_USER_GROUP = 'DELETE_USER_GROUP';
export const ADD_GROUP = 'ADD_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const ALL_GROUP_DETAILS = 'ALL_GROUP_DETAILS';
export const DOWNLOAD_GROUP_CONTACT = 'DOWNLOAD_GROUP_CONTACT';
export const DOWNLOAD_GROUP_CONTACT_URL = `${ API_URL_PATH }/api/group/gId/sheet`;

export const WEBSITE = 'WEBSITE';

export const SMS_HISTORY_STATUS = 'SMS_HISTORY_STATUS';
export const SMS_HISTORY_STATUS_URL = `${ UI_API_URL_PATH }/api/v1/org/me/campaign/{campaign_id}/delivery_status`;

export const SMS_HISTORY_SMART_STATUS = 'SMS_HISTORY_SMART_STATUS';
export const SMS_HISTORY_SMART_STATUS_URL = `${ SMSAPI_URL_PATH }/api/smsTransaction/url/tId`;

// export const SMS_HISTORY_DWNL_REPORT_URL = `${ SMSAPI_URL_PATH }/api/smsTransaction/sheet/generateUrl/tId`;
export const SMS_HISTORY_DWNL_REPORT_URL = `${ UI_API_URL_PATH }/api/v2/org/me/campaign/{campaign_id}/job/report`;
export const SMS_HISTORY_DWNL_REPORT_JOB_STATUS_URL = `${ UI_API_URL_PATH }/api/v1/org/me/campaign/job/{job_id}/status`;
export const SMS_HISTORY_DWNL_FILTER_REPORT_JOB_ID = 'SMS_HISTORY_DWNL_FILTER_REPORT_JOB_ID';
export const SMS_HISTORY_DWNL_FILTER_REPORT_URL = `${ API_URL_PATH }/api/sms/report?startDate=sDate&endDate=eDate`;
// export const SMS_HISTORY_DWNL_FULL_REPORT = `${ SMSAPI_URL_PATH }/api/smsTransaction/sheet/generateUrl/reports/`;
export const SMS_HISTORY_DWNL_FULL_REPORT = 'SMS_HISTORY_DWNL_FULL_REPORT';
export const SMS_HISTORY_DWNL_FULL_REPORT_URL = `${ UI_API_URL_PATH }/api/v1/org/me/campaign/job/report`;
export const GET_ALL_AGENT_DETAILS = 'GET_ALL_AGENT_DETAILS';
// export const AGENT_DETAILS_URL = `${ SMSAPI_URL_PATH }/api/institute`;
export const AGENT_DETAILS_URL = `${ UI_API_URL_PATH }/api/v1/org`;
export const ADD_BALANCE = 'ADD_BALANCE';
//export const ADD_BALANCE_URL = `${ SMSAPI_URL_PATH }/api/smsBalance?instituteId=instId`;
export const ADD_BALANCE_URL = `${ UI_API_URL_PATH }/api/v1/org/{instId}/balance`;
export const ADD_SMS_TYPE = 'ADD_SMS_TYPE';
//export const ADD_SMS_TYPE_URL = `${ SMSAPI_URL_PATH }/api/smsBalance/smsType?instituteId=instId`;
export const ADD_SMS_TYPE_URL = `${ UI_API_URL_PATH }/api/v1/org/{instId}/smstype`;
export const CHANGE_STATUS = 'CHANGE_STATUS';
// export const CHANGE_STATUS_URL = `${ SMSAPI_URL_PATH }/api/institute/accountstatus?instituteId=instId`;
export const CHANGE_STATUS_URL = `${ UI_API_URL_PATH }/api/v1/org/{instId}/tenant_status`;
export const UPDATE_OPERATOR = 'UPDATE_OPERATOR'
//export const UPDATE_OPERATOR_URL = `${ SMSAPI_URL_PATH }/api/institute/operatorAssigned?instituteId=instId`;
export const UPDATE_OPERATOR_URL = `${ UI_API_URL_PATH }/api/v1/org/{instId}/operator`
export const EDIT_DLT = 'EDIT_DLT'
//export const EDIT_DLT_URL = `${ SMSAPI_URL_PATH }/api/institute/dltPrincipalEntityId?instituteId=instId`;
export const EDIT_DLT_URL = `${ UI_API_URL_PATH }/api/v1/org/{instId}/dlt`;
export const ADD_SENDER_ID = 'ADD_SENDER_ID';
// export const ADD_SENDER_ID_URL = `${ SMSAPI_URL_PATH }/api/institute/senderIds?instituteId=instId`;
export const ADD_SENDER_ID_URL = `${ UI_API_URL_PATH }/api/v1/org/{instId}/senderid`;

export const CHANGE_DEFAULT_SENDER_ID = 'CHANGE_DEFAULT_SENDER_ID';
export const CHANGE_DEFAULT_SENDER_ID_URL = `${ UI_API_URL_PATH }/api/v1/org/{instId}/default_senderid`;
export const POST_AGENT_DETAILS = 'POST_AGENT_DETAILS';

export const BULK_CONTACT = 'BULK_CONTACT';
export const DELETE_BULK_CONTACT = 'DELETE_BULK_CONTACT';
export const BULK_CONTACT_URL = `${ API_URL_PATH }/api/contact/bulk`;

export const SCHEDULE_SMS_URL = `${ SMSAPI_URL_PATH }/api/sms/schedule`;
export const GET_SCHEDULED_SMS = 'GET_SCHEDULED_SMS';
export const RE_SCHEDULED_SMS = 'RE_SCHEDULED_SMS';
export const CANCEL_SCHEDULED_SMS = 'CANCEL_SCHEDULED_SMS';

export const SMS_DETAILS = 'SMS_DETAILS';
export const SMS_DETAILS_URL = `${ UI_API_URL_PATH }/api/v1/org/me/balance`;
// export const SMS_DETAILS_URL = `${ SMSAPI_URL_PATH }/api/smsBalance`;

export const DASHBOARD_DETAILS = 'DASHBOARD_DETAILS';
export const DASHBOARD_DETAILS_URL = "" //`${ SMSAPI_URL_PATH }/api/dashboard/v2`;


//export const CHANGE_PASSWORD_URL = `${ API_URL_PATH }/api/auth/c/update`;
export const CHANGE_PASSWORD_URL = `${ UI_API_URL_PATH }/api/v1/org/{instId}/account/{accountId}/password`;
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';


export const ADD_BLOCK_NUMBER_URL = `${ SMSAPI_URL_PATH }/api/blockednumbers`;
export const ADD_BLOCK_NUMBER = 'ADD_BLOCK_NUMBER';
export const GET_BLOCK_NUMBER = 'GET_BLOCK_NUMBER';
export const GET_OWNED_BLOCKED_CONTACT = 'GET_OWNED_BLOCKED_CONTACT';
export const BLOCK_CONTACT = 'BLOCK_CONTACT';
export const DELETE_BLOCK_CONTACT = 'DELETE_BLOCK_CONTACT';
// export const BLOCKED_CONTACT_URL = `${ SMSAPI_URL_PATH }/api/blockednumbers`;
export const OWN_BLOCKED_CONTACT_URL = `${ UI_API_URL_PATH }/api/v1/org/me/block_number`;
// export const ALL_BLOCKED_CONTACT_URL = `${ UI_API_URL_PATH }/api/v1/org/admin/block_number`;

export const DELETE_SENDER_ID_URL = `${ UI_API_URL_PATH }/api/v1/org/{instId}/senderid/{senderid}`;
// export const DELETE_SENDER_ID_URL = `${ SMSAPI_URL_PATH }/api/institute/senderIds`;
export const DELETE_SENDER_ID = 'DELETE_SENDER_ID';
// Template
export const GET_TEMPLATE = 'GET_TEMPLATE';
//export const GET_TEMPLATE_URL = `${ SMSAPI_URL_PATH }/api/template`;
export const GET_TEMPLATE_URL = `${ UI_API_URL_PATH }/api/v1/org/me/template`;

export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const POST_TEMPLATE = 'POST_TEMPLATE';
export const EDIT_TEMPLATE = 'EDIT_TEMPLATE';

export const DASHBOARD_SMS_STATS = 'DASHBOARD_SMS_STATS'
export const DASHBOARD_SMS_STATS_URL = `${UI_API_URL_PATH}/api/v1/org/analytics/dashboard`;
export const CAMAIGN_DETAILS = `${UI_API_URL_PATH}/api/v1/org/me/campaign/{campaign_id}`;

export const BRAND_LOGO_LINK = process.env.REACT_APP_BRAND_LOGO

export const GET_CREDIT_HISTORY = 'GET_CREDIT_HISTORY';
export const GET_CREDIT_HISTORY_URL = `${ UI_API_URL_PATH }/api/v1/org/me/balance/credit_history`;
export const UI_API_CONTACT_US_URL_PATH = `${UI_API_URL_PATH}/api/v1/org/me/contact_us` ;